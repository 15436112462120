.swiper-button-next, .swiper-button-prev {
    cursor: pointer;
}

.information {
    cursor: default;
}

@media (max-width:768px) {
    .swiper-slide-active .image-wrapper{
        border: none;
    }
}