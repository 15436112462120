@import "../../styles/Theme.module.scss";
.program-block {
  background-color: $black;
  padding: 100px 0;
}
.content {
  max-width: 1140px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 62px;
}
.subtitle {
  color: $white;
  font-family: $text3-font-family;
  line-height: $text3-line-height;
  font-size: 24px;
  text-align: center;
  margin: 0;
  margin-top: -20px;
}
ul {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.items-component {
  font-family: $text8-font-family;
  line-height: $text8-line-height;
  font-size: 16px;
  color: $white;
  margin: 0;
}
.program-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 67px;
  margin-top: 0px;
  margin-bottom: 28px;
  row-gap: 40px;
}
.item-block {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.contentBlock {
  display: flex;
  flex-direction: column;
}
.buttonOpen {
  background: none;
  color: $gray;
  border: none;
  align-self: self-end;
  font-family: $text11-font-family;
  line-height: $text11-line-height;
  font-size: 12px;
  &:hover {
    color: $pink;
  }
}
.item {
  display: flex;
}
.text-item {
  color: $white;
  font-family: $text3-font-family;
  line-height: $text3-line-height;
  display: flex;
  align-items: center;
  gap: 9px;
}

@media (max-width: 768px) and (min-width: 320px) {
  .program-block {
    background-color: $black;
    width: 100%;
    padding: 60px 15px;
    padding: 60px 0px;
  }
  .content {
    max-width: 100%;
    gap: 30px;
  }
  .subtitle {
    font-size: 16px;
  }
  .items-component {
    font-size: 14px;
  }
  .program-content {
    display: flex;
    flex-direction: column;
    margin-top: 19px;
    margin: 0 15px;
    row-gap: 32px;
  }
  .text-item {
    font-size: 16px;
  }
}
