@import "../../styles/Theme.module.scss";

.news-block {
  padding: 100px 0;
  background: url(../../assets/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  background-position: bottom;
}

.content {
  max-width: 1140px;
  width: 100%;
  margin: auto;
}

.cards-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
  position: relative;
}

.content-head {
  display: flex;
  width: 100%;
}

.title-head {
  margin: 0 auto;
  margin-left: 43%;
}

.title-details {
  margin: 0 auto;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  justify-self: flex-end;
  cursor: pointer;
  text-decoration: none;
  color: $black;

  &:hover {
    color: $pink;
  }
}

.card-icon {
  position: absolute;
  top: -5%;
  margin-left: -3%;
}

.card {
  border: 3px solid $black;
  padding: 10px 10px 12px 10px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  max-width: 340px;
  position: relative;
  gap: 10px;

  img {
    object-fit: cover;
  }

  &:hover {
    border: 3px solid $pink;

    img {
      transform: scale(1.05);
      transition: 1s;
      display: block;
    }

    .card-link {
      color: $pink;
    }

    .card-icon {
      transform: rotate(-25deg);
      z-index: 1;
    }
  }
}

.card-link {
  display: flex;
  align-self: self-end;
  align-items: center;
  color: $black;
  // height: 100%;
}

.card-photo {
  height: 320px;
}

.news-text {
  color: $black;
  font-family: $text5-font-family;
  line-height: $text5-line-height;
  font-size: 20px;
  margin: 0;
  margin-top: 26px;
  max-width: 320px;
}

.news-day {
  color: $black;
  font-family: $text10-font-family;
  line-height: $text10-line-height;
  font-size: 14px;
  margin: 0;
}

@media (max-width: 1200px) and (min-width: 769px) {
  .cards-container {
    justify-content: center;
    gap: 60px;
  }
}

@media (max-width: 768px) and (min-width: 320px) {
  .news-block {
    padding: 58px 0;
  }
  .content {
    max-width: 100%;
  }
  .content-head {
    flex-wrap: wrap;
    justify-content: end;
  }
  .title-head {
    flex: 1 100%;
    margin-left: 0;
  }
  .link {
    flex: 1 100%;
    justify-self: flex-end;
    align-self: flex-end;
  }
  .cards-container {
    gap: 30px;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 32px;
    position: relative;

  }
  .card {
    box-sizing: border-box;
    max-width: 290px;
    width: 100%;
    margin: auto;
    border: 3px solid $black;
    display: flex;
    flex-direction: column;
    background-color: $white;
    position: relative;
    padding: 0 10px;

    &:hover {
      border: 3px solid $pink;
      overflow: hidden;

      img {
        transform: scale(1.05);
        transition: 1s;
        display: block;

      }

      .card-link {
        color: $pink;
      }

      .card-icon {
        transform: rotate(-25deg);
        z-index: 1;
      }
    }
  }
  .news-text {
    font-size: 18px;
  }
  .news-day {
    font-size: 12px;
  }
  .card-link {
    font-size: 14px;
  }
  .card-icon {
    width: 45px;
    height: 45px;
  }
}
