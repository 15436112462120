@import "../../styles/Theme.module.scss";
.directions-block {
  background-color: $gray;
  padding: 100px 0;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 59px;
  max-width: 1140px;
  width: 100%;
  margin: auto;
  
}
.cards {
  display: flex;
  flex-direction: column;
  gap: 68px;
}
.green,
.blue,
.yellow,
.pink {
  display: flex;
  padding: 20px 71px;
  gap: 30px;
  align-items: center;
}
.green {
  background-color: $green;
}
.blue {
  background-color: $blue;
}
.pink {
  background-color: $pink;
}
.yellow {
  background-color: $yellow;
}
.container-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-title {
  line-height: $text2-line-height;
  font-family: $text2-font-family;
  color: $black;
  font-size: 34px;
  margin: 0;
}
.card-text {
  line-height: $text3-line-height;
  font-family: $text3-font-family;
  color: $black;
  font-size: 18px;
  margin: 0;
}

@media (max-width: 768px) and (min-width: 320px) {
  .directions-block {
    padding: 60px 15px;
    padding: 60px 0px;
    overflow: hidden;
    width: 100%;
  }
  .card-title {
    font-size: 24px;
  }
  .card-text {
    font-size: 16px;
  }
  .cards {
    gap: 30px;
    margin: 0 15px;
  }
  .content {
    padding: 0 0px;
    width: 100%;
  }
  .green,
  .blue,
  .yellow,
  .pink {
    display: flex;
    flex-direction: column;
    padding: 26px 20px;
    gap: 30px;
    align-items: flex-start;
    // .img{
    //     width: 50px;
    //     height: 50px;
    // }
  }
}
