@import "../../styles/Theme.module.scss";

.advantages-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  position: relative;
  margin-top: 100px;
}
.star-image {
  position: absolute;
  right: 0;
  top: -10%;
}
.container-cards {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}
.card {
  border: 3px solid $black;
  display: grid;
  grid-template-rows: 4fr 2fr;
}
.logo {
  width: 234px;
  height: 60px;
}
.advantages-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 35px;
}
.text-block {
  background-color: $white;
  margin: 0;
  padding: 31px 50px 31px 50px;
  font-family: $text8-font-family;
  line-height: $text8-line-height;
  font-size: 16px;
}
.black,
.pink {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $text2-font-family;
  line-height: $text3-line-height;
  font-size: 34px;
}
.pink {
  background-color: $pink5;
  color: $black;
}
.black {
  background-color: $black;
  color: $pink5;
}

@media (max-width: 768px) and (min-width: 320px) {
  .advantages-block {
    padding: 60px 15px;
    padding: 60px 0px;
    width: 100%;
    margin-top: 0;
  }
  .star-image {
  width: 71px;
  height: 50px;
  right: 35%;
  top: -20px;
  }
  .advantages-cards {
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin: 0 15px;
  }
  .logo {
    display: none;
  }
  .text-block {
    font-size: 14px;
    padding: 10px 10px 20px 10px;
  }
  .black,
  .pink {
    font-size: 20px;
  }
  .card {
    grid-template-rows: 3fr 1fr;
  }
  .container-cards{
      gap: 28px;
      }
}
