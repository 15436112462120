@import "../../styles/Theme.module.scss";

.content {
  padding-top: 194px;
  position: relative;
  width: 100%;
}

.image-star {
  position: absolute;
  right: 2%;
  top: 20%;
  transform: rotate(250deg);
}

.image-stars {
  justify-self: center;
  align-self: center;
  margin-top: 101px;
  margin-bottom: 141px;
}

.news-block {
  max-width: 1140px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  flex-grow: 0;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  position: relative;
  row-gap: 80px;
  column-gap: 3%;
}

.card-icon {
  position: absolute;
  margin-top: -5%;
  margin-left: -3%;
}

.card {
  border: 3px solid $black;
  padding: 10px 10px 12px 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  max-width: 340px;
  height: 100%;

  img {
    object-fit: cover;
    max-width: 320px;
  }

  &:hover {
    border: 3px solid $pink;
    overflow: hidden;

    img {
      transform: scale(1.05);
      transition: 1s;
      display: block;
    }

    .card-link {
      color: $pink;
    }

    .card-icon {
      transform: rotate(-25deg);
      z-index: 1;
    }
  }
}

.card-link {
  display: flex;
  align-self: self-end;
  align-items: center;
  color: $black;
}

.card-photo {
  height: 320px;
}

.news-text {
  color: $black;
  font-family: $text5-font-family;
  line-height: $text5-line-height;
  font-size: 20px;
  margin: 0;
  margin-top: 26px;
  max-width: 320px;
  min-height: 60px;
}

.news-day {
  color: $black;
  font-family: $text10-font-family;
  line-height: $text10-line-height;
  font-size: 14px;
  margin: 0;
}

@media (max-width: 1200px) and (min-width: 768px) {
  .cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 60px;
    position: relative;
    gap: 80px;
  }
}

@media (max-width: 768px) and (min-width: 320px) {
  .content {
    padding-top: 101px;
  }
  .card-icon {
    width: 45px;
    height: 40px;
  }
  .image-star {
    width: 71px;
    height: 60px;
  }
  .image-stars {
    width: 98px;
    height: 98px;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .news-block {
    display: flex;
    max-width: 320px;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
  }
  .cards-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;
    position: relative;
    row-gap: 40px;
    padding: 0 15px;
  }
  .card {
    box-sizing: border-box;
    max-width: 290px;
    width: 100%;
    margin: auto;
    border: 3px solid $black;
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 0 10px;

    &:hover {
      border: 3px solid $pink;
      overflow: hidden;

      img {
        transform: scale(1.05);
        transition: 1s;
        display: block;

      }

      .card-link {
        color: $pink;
      }

      .card-icon {
        transform: rotate(-25deg);
        z-index: 1;
      }
    }
  }
  .news-text {
    font-size: 18px;
  }
  .news-day {
    font-size: 12px;
  }
  .card-link {
    font-size: 14px;
  }
}
