@import "../../styles/Theme.module.scss";
.button-black {
  background-color: $pink;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid $black;
  line-height: $text5-line-height;
  font-family: $text5-font-family;
  color: $black;
  padding: 11px 17px;
  position: relative;
  background-image: url(../../assets/Texture.png);
  background-blend-mode: overlay;
  text-transform: uppercase;
  z-index: 1;
  &:hover{
    background-color: $black;
    color: $pink;

    &::before {
      content: "";
      background: url(../../assets/border-pink.png);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 107%;
      height: 53px;
      z-index: 10;
    }

  }
  cursor: pointer;
  &::before {
    content: "";
    background: url(../../assets/border-black.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 107%;
    height: 53px;
    z-index: 10;
  }
}

.button-white {
  background-color: $pink;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid $black;
  line-height: $text5-line-height;
  font-family: $text5-font-family;
  color: $white;
  padding: 11px 17px;
  position: relative;
  background-image: url(../../assets/Texture.png);
  background-blend-mode: overlay;
  text-transform: uppercase;
  z-index: 1;
  cursor: pointer;

  &:hover{
    background-color: $white;
    color: $pink;
    &::before {
      content: "";
      background: url(../../assets/border-pink.png);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 107%;
      height: 53px;
      z-index: 10;
    }
  }

  &::before {
    content: "";
    background: url(../../assets/border-button-white.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 107%;
    height: 53px;
    z-index: 10;
  }
}
@media (max-width: 768px) and (min-width: 320px) {
  .button-white,
  .button-black {
    font-size: 14px;
    padding: 11px 26px;
  }
}
