$white: #ffffff;
$black: #222222;
$gray: #f4f4f4;
$blue: #00d1ff;
$red: #ec0009;
$yellow: #ffd400;
$green: #02da69;
$pink: #ff77b8;
$pink2: #ff81c2;
$pink3: #ff8bcc;
$pink4: #ff96d7;
$pink5: #ff77b8;

$h1-line-height: 140%;
$h1-font-family: Podkova-SemiBold;

$h2-line-height: 140%;
$h2-font-family: Podkova-Bold;

$h3-line-height: 160%;
$h3-font-family: Podkova-Regular;

$h4-line-height: 160%;
$h4-font-family: Podkova-Bold;

$h5-line-height: 140%;
$h5-font-family: Podkova-Bold;

$subtitle-line-height: 160%;
$subtitle-font-family: Podkova-Bold;

$text1-line-height: 160%;
$text1-font-family: Montserrat-SemiBold;

$text2-line-height: 140%;
$text2-font-family: Podkova-Bold;

$text3-line-height: 160%;
$text3-font-family: Montserrat-Medium;

$text4-line-height: 160%;
$text4-font-family: Montserrat-Bold;

$text5-line-height: 140%;
$text5-font-family: Podkova-Bold;

$text6-line-height: 160%;
$text6-font-family: Montserrat-Medium;

$text7-line-height: 175%;
$text7-font-family: Podkova-Regular;

$text8-line-height: 160%;
$text8-font-family: Montserrat-Regular;

$text9-line-height: 140%;
$text9-font-family: Podkova-Medium;

$text10-line-height: 140%;
$text10-font-family: Montserrat-Medium;

$text11-line-height: 140%;
$text11-font-family: Montserrat-Regular;
