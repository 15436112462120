@import '../../styles/Theme.module.scss';
.main-block{
    padding: 283px 0 133px 0;
    position: relative;
}
.message-image{
position: absolute;
top: 240px;

}
.skills-pink,
.skills-yellow,
.skills-green,
.skills-blue{
padding: 16px 28px;
border-radius: 30px;
position: absolute;
text-decoration: none;
line-height: $text5-line-height;
font-family: $text5-font-family;
text-transform: uppercase;
color: $black;
font-size: 16px;
&:hover{
    border: 4px solid  #222;
}
}
.skills-pink{
    background-color: $pink;
    left: 20%;
    top:200px;
}
.skills-green{
background-color: $green;
left: 15%;
top:81%;
}
.skills-yellow{
    background-color: $yellow;
    z-index: 2;
    right: 10%;
    top:250px;
}
.skills-blue{
background-color: $blue;
right: 9%;
top:88%;
}
.star-image{
position: absolute;
top:600px;
right: 0;
}
.content{
    background: url(../../assets/bg.png);
    background-color: $pink;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 67px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 29px;
}
.main-title{
    font-family: $h1-font-family;
    line-height: $h1-line-height;
    color: $white;
    font-size: 44px;
    text-align: center;
}
.text-gitl{
    position: relative;
    .line-image{
        position: absolute;
        right:15px;
        bottom: -15px;
    }
    .star-image-top{
        position: absolute;
        top: -10px;
        left: -20px;

    }
    .star-image-bottom{
        position: absolute;
        bottom: -20px;
        right: -10px;

    }
}

@media (max-width:768px) and (min-width:320px) {
    .main-block{
        padding: 191px 0 120px 0;
    }
    .content{
        padding: 40px 0;
    }
.star-image{
display: none;
}
.message-image{
    width: 77px;
    height: 77px;
    top: 150px;
    left: -40px;
}
.main-title{
    font-size: 36px;
}
.line-image{
    left: 0%;
    top:40px;
    width: 158px;
    height: 17px;
}
.star-image-bottom,
.star-image-top{
    width: 24px;
    height: 31px;
}
.star-image-top{
    top: -8px;
    left: -10px;
}
.star-image-bottom{
    bottom: 0px;
    right: 0px;
}
.skills-pink{
padding: 14px 20px;
font-size: 12px;
top: 91px;
left: -20px;
}
.skills-yellow{
    padding: 14px 20px;
    font-size: 12px;
    top: 151px;
    right: 0%;
    }
    .skills-blue{
        padding: 14px 20px;
        font-size: 12px;
        top: 93%;
        right: 0%;
        }
        .skills-green{
            padding: 14px 20px;
            font-size: 12px;
            top: 87%;
            left: -10px;
            }
}
