@import "../../styles/Theme.module.scss";

.form-block {
  background-color: $black;
  padding: 100px 0;
  position: relative;
}
.texterea{
  width: 0px;
  height: 0px;
  display: none;
}
.arrow-image {
 right: 0;
 position: absolute;
 top: -60px;
 width: 20%;

}
.question-image {
  position: absolute;
  margin-top: 25%;
  left: 4%;
  bottom: 2%;
  @media (max-width:1150px) {
    display: none;
  }
}
.content {
  max-width: 740px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.successful-content {
  max-width: 740px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  margin-top: 112px;
  margin-bottom: 41px;
  p {
    color: $pink;
    font-family: $subtitle-font-family;
    line-height: $subtitle-line-height;
    font-size: 30px;
  }
}
.form-subtitle {
  color: $white;
  font-family: $text6-font-family;
  line-height: $text6-line-height;
  font-size: 18px;
  text-align: center;
  margin: 0;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
  width: 740px;
  margin: 60px 0 35px 0;
  
}
.input-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 22px;
  width: 100%;
}
.group-email{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
}

.form-input {
  background: none;
  width: 100%;
  color: $white;
  font-family: $text7-font-family;
  line-height: $text7-line-height;
  font-size: 16px;
  padding: 23px 40px;
  border: none;
  background-image: url(../../assets/input-border.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &:focus{
    background-image: url(../../assets/focus-input-border.png);
  }
 
}

.border-error{
  background-image: url(../../assets/input-border-error.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.border-success{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/input-success-border.png);
}


input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: $white;
  font-family: $text7-font-family;
  line-height: $text7-line-height;
  font-size: 16px;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: $white;
  font-family: $text7-font-family;
  line-height: $text7-line-height;
  font-size: 16px;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: $white;
  font-family: $text7-font-family;
  line-height: $text7-line-height;
  font-size: 16px;
}

input::placeholder,
textarea::placeholder {
  color: $white;
  font-family: $text7-font-family;
  line-height: $text7-line-height;
  font-size: 16px;
}

@media (max-width:768px) and (min-width:320px) {
  .form-block {
    background-color: $black;
    padding: 50px 15px;
  }
  .question-image {
   display: none;
  }
  .arrow-image{
    right: 0;
    top: -20px;
    width: 77px;
    height: 100px;
    position: absolute;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .successful-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 30px;
    p {
      color: $pink;
      font-family: $subtitle-font-family;
      line-height: $subtitle-line-height;
      font-size: 28px;
      text-align: center;
    }
  }
  .form-subtitle {
    font-size: 14px;
    margin-top: -20px;
  }
  .form {
    gap: 12px;
    width: 100%;
 overflow: hidden;
  }
  .input-group {
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  .group-email{
      width: 100%;
  }
  
  .form-input {
    font-size: 14px;
    padding: 12px 20px;
    border: none;  
  }
  
  .border-error{
    background-image: url(../../assets/input-border-error.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  .border-success{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../../assets/input-success-border.png);
  }
  
  
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: $white;
    font-family: $text7-font-family;
    line-height: $text7-line-height;
    font-size: 14px;
  }
  
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: $white;
    font-family: $text7-font-family;
    line-height: $text7-line-height;
    font-size: 14px;
  }
  
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: $white;
    font-family: $text7-font-family;
    line-height: $text7-line-height;
    font-size: 14px;
  }
  
  input::placeholder,
  textarea::placeholder {
    color: $white;
    font-family: $text7-font-family;
    line-height: $text7-line-height;
    font-size: 14px;
  }
}
