@import '../../styles/Theme.module.scss';

.button {
    background-color: $pink;
    border-radius: 10px;
    font-size: 16px;
    border: 1px solid $black;
    line-height: $text5-line-height;
    font-family: $text5-font-family;
    color: $black;
    padding: 13px 38px;
    position: relative;
    background-image: url(../../assets/Texture.png);
    background-blend-mode: overlay;
    text-transform: uppercase;
    z-index: 1;
    cursor: pointer;

    &:hover{
        background-color: $black;
        color: $pink;
    
        &::before {
          content: "";
          background: url(../../assets/border-pink.png);
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 107%;
          height: 53px;
          z-index: 10;
        }
    
      }

    &::before {
      content: "";
      background: url(../../assets/border-black.png);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 107%;
      height: 53px;
      z-index: 10;
    }
    @media (max-width: 768px) and (min-width: 320px) {

          font-size: 14px;
          padding: 11px 44px;
        
      }
  }

.notFound-page{
    padding-top: 194px;
    padding-bottom: 100px;
    position: relative;
}
.progremming{
    position: absolute;
    left: 5%;
    transform: rotate(-10deg);
   top: 14%;
}
.design{
    position: absolute;
    top: 22%;
    right: 5%;
    transform: rotate(7deg);
}
.error-group{
    display: flex;
    gap: 30px;
}
.creation{
    position: absolute;
    bottom: 7%;
    left: 10%;
    transform: rotate(10deg);
}
.leadership{
    position: absolute;
    right: 10%;
    bottom: 7%;
}


.content{
    background: url(../../assets/bg.png);
    background-color: $pink;
    mix-blend-mode: overlay;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 67px 0;
    gap: 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.error-images{
    display: flex;
    justify-content: center;
    gap:67px;
    align-items: center;
}
.error-text{
    color: $black;
    line-height: $text6-line-height;
    font-family: $text6-font-family;
    font-size: 18px;
}
@media (max-width:948px) and (min-width:748px) {

    .black-row{
        width: 42px;
        height: 56px;
    }
    .fout-error{
        width: 72px;
        height: 105px;
    }
    .sun-error{
        width: 105px;
        height: 105px;
    }
    .error-images{
        gap:20px;
    }
    .black-row{
        width: 42px;
        height: 56px;
    }
}

@media (max-width:748px) and (min-width:320px) {
    .notFound-page{
        padding-top: 104px;
        padding-bottom: 53px;
    }
    .error-text{
        font-size: 14px;
    }
    .error-images{
        gap:10px;
    }
    .error-group{
        gap: 0px;
        justify-content: space-between;
    }
    .progremming,
    .leadership,
    .design,
    .creation{
     width: 50px;
     height: 50px;
    }
    .black-row{
        width: 32px;
        height: 46px;
    }
    .fout-error{
        width: 62px;
        height: 95px;
    }
    .sun-error{
        width: 95px;
        height: 95px;
    }
   
}