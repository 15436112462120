@import "../../styles/Theme.module.scss";

.footer-block {
  background-color: $black;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  align-items: center;
  padding: 30px 150px;
  // padding: 30px 0;
  align-items: center;
}
.links-block {
  display: flex;
  gap: 16px;
  order: 3;
}
.link{
  display: flex;
  justify-content: center;
  order: 1;
}
.footer-text {
  font-size: 14px;
  color: $white;
  font-family: $text6-font-family;
  line-height: $text6-line-height;
  order: 2;
  &:hover{
    color: $pink;
  }
}
.footer-text-small{
  display: none;
}

@media (min-width:320px) and (max-width:768px) {
  .footer-block {
   padding: 41px 0px 41px 0px;
   flex-wrap: wrap;
  }
  .footer-text{
    font-size: 14px;
    flex: 1 1 100%;
    text-align: center;
    order: 3;
    margin-top: 33px;
  }
  .link{
   width: 117px;
   height: 30px;
   flex: 1 1 50%;
   order: 1;
  }
  .links-block {
    flex: 1 1 50%;
    order: 2;
    justify-content: center;
    img{
      width: 31px;
   height: 31px;
    }
  }
  
}
