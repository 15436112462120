@import '../../../styles/Theme.module.scss';

.chaptersText{
    color:$black;
    line-height: $text8-line-height;
    font-family: $text8-font-family;
    font-size: 16px;
    @media (max-width:768px) and (min-width:320px) {
        font-size: 14px;
    }
}