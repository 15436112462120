@import "../../styles/Theme.module.scss";

.schedule-block {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  background: url(../../assets/yellow-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
}
.card-schedule {
  padding: 16px 111px 67px 111px;
  border: 4px solid $black;
  justify-content: center;
  background: $white;
  transform: rotate(1deg);
  position: relative;
  &::before {
    content: "";
    background: $white;
    border: 4px solid $black;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    transform: rotate(-2deg);
    &:hover{
      transform: rotate(1deg);
    }
  }
  &:hover{
    transform: rotate(0deg);
  }
}
.backtack {
  margin-left: -81px;
}
.days-items {
  display: flex;
  justify-content: space-between;
  gap: 39px;
  align-items: center;
  margin-top: 37px;
}
.day-text {
  color: $black;
  font-family: $text7-font-family;
  line-height: $text6-line-height;
  font-size: 40px;
  margin: 0;
}
.time-text {
  color: $black;
  font-family: $text2-font-family;
  line-height: $text6-line-height;
  font-size: 30px;
  margin: 0;
}
.hearts {
  position: absolute;
bottom: -30px;
right: -170px;
max-width: 320px;
max-height: 320px;
width: 100%;
@media (max-width:881px) and (min-width:768px) {
  max-width: 220px;
max-height: 220px;
right: -100px;
width: 100%;
}
}

@media (max-width:768px) and (min-width:320px) {
  .schedule-block {
    padding: 60px 0px;
    width: 100%;
  }
  .backtack {
   width: 39px;
   height: 32px;
   position: absolute;
   margin-left: 0;
   top: -1px;
   left: 20px;
  }
  .day-text {
    font-size: 28px;
    margin: 0;
  }
  .days-items {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-top: 0px;
  }
  .time-text {
    font-size: 24px;
    margin: 0;
  }
  .card-schedule {
    padding: 29px 22px;
    position: relative;
  }
  .hearts{
    width: 120px;
    height: 120px;
    position: absolute;
    bottom: -70px;
    right: -10px;
  }
}
