@import '../../styles/Theme.module.scss';
.header-block{
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // padding: 24px 150px;
    padding: 24px 0px;
    background-color: $white;
    z-index: 20;
}
.link{
    display: flex;
    justify-content: center;
}
.header-tel{
    display: none;
}
.header-burger{
    display: none;
}
@media  (max-width:970px) and (min-width:769px) {
    .header-block{
        padding: 13px 0px;
    }
    .header-tel{
        display: flex;
        flex-direction: column;
        z-index: 1000;
        a{
        color: $pink;
        font-family: $subtitle-font-family;
        line-height: 160%;
        font-size: 18px;
        text-decoration: none;
        transform: rotate(-4deg);
        }
    }
}
@media  (max-width:768px) and (min-width:320px) {
    .header-block{
        padding: 13px 0px;
    }
    .link{
        width: 62px;
        height: 16px;
    }
    .header-tel{
        display: flex;
        flex-direction: column;
        z-index: 1000;
        a{
        color: $pink;
        font-family: $subtitle-font-family;
        line-height: 160%;
        font-size: 14px;
        text-decoration: none;
        transform: rotate(-4deg);
        }
    }
    .header-burger{
        display: inline;
    }
}