@font-face {
  font-family: Podkova-Bold;
  src: url(./assets/fonts/Podkova-Bold.ttf);
  font-display:swap;
}
@font-face {
  font-family: Podkova-SemiBold;
  src: url(./assets/fonts/Podkova-SemiBold.ttf);
  font-display:swap;
}
@font-face {
  font-family: Podkova-Regular;
  src: url(./assets/fonts/Podkova-Regular.ttf);
  font-display:swap;
}
@font-face {
  font-family: Podkova-Medium;
  src: url(./assets/fonts/Podkova-Medium.ttf);
  font-display:swap;
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(./assets/fonts/Montserrat-Medium.ttf);
  font-display:swap;
}
@font-face {
  font-family: Montserrat-Regular;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  font-display:swap;
}
@font-face {
  font-family: Montserrat-Bold;
  src: url(./assets/fonts/Montserrat-Bold.ttf);
  font-display:swap;
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf);
  font-display:swap;
}

@import "./styles/Theme.module.scss";

html {
  scroll-behavior: smooth;
 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.team-slider {
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swiper-button-next {
  height: 58.33px;
  left: 62%;
  right: 0;
  position: absolute;
  top: 420px;
  width: 40.46px;
  @media (max-width: 768px) and (min-width: 320px) {
    height: 35px;
    left: 91.5%;
    top: 180px;
    width: 24px;
  }
  &::before {
    content: "";
    background: url(assets/arrow-slider.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
.swiper-button-prev {
  height: 58.33px;
  left: 35%;
  position: absolute;
  top: 420px;
  width: 40.46px;
  @media (max-width: 768px) and (min-width: 320px) {
    height: 35px;
    left: 5px;
    top: 175px;
    left: 1%;
    width: 24px;
  }
  &::before {
    content: "";
    background: url(assets/arrow-slider.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
  }
}




.swiper-slide {
  display: flex;
  flex-direction: column;
  position: relative;
  background-position: center;
  background-size: cover;
  width: 320px !important;
  margin-top: 50px;
  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: block;
    width: 100%;
    & > img {
      border-radius: 50%;
      width: 320px !important;
      height: 320px;
      object-fit: cover;
    }
  }
  & > .information {
    background-color: white;
    box-shadow: 0px 5px 36px rgba(0, 0, 0, 0.12);
    transform: matrix(1, 0.03, -0.03, 1, 0, 0);
    padding: 12px 20px;
    margin-top: -20px;
    .title {
      color: #222222;
      font-family: $text5-font-family;
      font-size: 20px;
      text-align: center;
      margin: 0;
    }
    .text {
      color: #222222;
      font-family: $text8-font-family;
      font-size: 16px;
      margin: 0;
      margin-top: 10px;
      line-height: $text8-line-height;
      @media (max-width: 768px) and (min-width: 320px) {
        font-size: 14px;
      }
    }
  }
  &:not(.swiper-slide-active) {
    & > .information {
      display: none;
    }
  }

  @media (max-width: 768px) and (min-width: 320px) {
    .image-wrapper {
      width: 280px !important;
      height: 280px;
      margin-top: 0;
    }
  }
}
.swiper-slide-active {
  display: flex;
  flex-direction: column;
  position: relative;
  background-position: center;
  background-size: cover;
  width: 320px !important;
  margin-top: 50px;
  .image-wrapper {
    display: block;
    width: 100%;
    display: flex;
    border: 11px solid #ff77b8;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    @media (max-width: 768px) and (min-width: 320px) {
      width: 280px !important;
      height: 280px;
      margin-top: 0;
  }
    & > img {
      border-radius: 50%;
      width: 320px !important;
      height: 320px;
      object-fit: cover;
    }
  }
 
}

.swiper-slide img {
  display: block;
  width: 100%;
}