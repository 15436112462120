@import "../../styles/Theme.module.scss";

.target-block {
  padding: 100px 0;
  display: flex;
  justify-content: flex-end;
}

.content {
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 34px;
  position: relative;
}

.text-content {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-right: 93px;
  width: 50%;
  position: relative;
}

.quotes-image {
  position: absolute;
  top: -13%;
}

.target-learning {
  font-family: $text2-font-family;
  line-height: $text1-line-height;
  font-size: 30px;
  color: $black;
  margin: 0;
}

.target-language {
  font-family: $text3-font-family;
  line-height: $text3-line-height;
  font-size: 16px;
  color: $black;
  margin: 0;
}

.founder-items {
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 40px;
    height: 34px;
  }
}

.founder {
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  position: relative;
}

.founder-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.lines-image {
  position: absolute;
  top: -12%;
  left: -14%;
}

.flower-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 1169px) and (min-width: 768px) {
  .target-block {
    padding: 100px 0;
    display: flex;
    justify-content: center;
    max-width: 768px;
    width: 100%;
    margin: auto;
  }
  .lines-image {
    display: none;
  }
  .founder {
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 60px;
    padding: 0;
    align-items: center;
    justify-self: center;
  }
  .quotes-image {
    top: -50px;
  }
  .container-content {
    width: 100%;
  }
  .text-content {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 768px) and (min-width: 320px) {
  .target-block {
    margin: 80px 15px;
    display: flex;
    padding: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding: 0;
  }
  .target-learning {
    font-size: 16px;
  }
  .target-language {
    font-size: 14px;
  }
  .founder-items {

    gap: 13px;

    img {
      width: 17px;
      height: 14px;
    }
  }
  .quotes-image {
    position: absolute;
    top: -50px;
  }
  .container-content {
    width: 100%;
    gap: 29px;
  }
  .text-content {
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding-top: 0;
    padding-right: 0;
    width: 100%;
  }
  .founder {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
  }
  .flower-image {
    width: 99px;
    height: 99px;
    bottom: 0;
    right: 0;
  }
  .lines-image {
    display: none;
  }
}
