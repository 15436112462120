@import "../../styles/Theme.module.scss";

.contact-block {
  padding: 100px 0;
  position: relative;
}
.petals-image{
  position: absolute;
  right: 0;
  bottom: 5%;
}

.content {
  max-width: 1140px;
  width: 100%;
  margin: auto;
}
.contact-items {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 60px;
  flex-wrap: nowrap;
  gap: 146px;
}
.rowsBlock{
  display: flex;
  align-items: center;
  img{
    display: none;
  }
  &:hover{
    img{
      display: block;
    }
  }
}
.mapBlock {
position: relative;
z-index: 1;
&::before{
  content: '';
  background: url(../../assets/border-map.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 128%;
  height: 125%;
  top: -13%;
  bottom: 0;
  right: 0;
  left: -12%;
  z-index: -1;
}
img {
  border: 6px solid #222222;
  border-radius: 42px;
  max-width: 403px;
  width: 100%;
}
}
.contact-item{
    transform: rotate(-7deg);
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.contact-logo{
  width: 234px;
  height: 60px;
}
.contact-number,
.contact-link{
font-family: $text9-font-family;
line-height: $text9-line-height;
text-decoration: none;
margin: 0;
}
.contact-number{
    font-size: 20px;
    color: $pink;
}
.contact-link{
    font-size: 20px;
    color: $blue;
}
.contact-link-icon{
  display: flex;
  gap: 17px;
}

@media (max-width:768px) and (min-width:320px) {
  .contact-block {
    padding: 60px 15px;
    position: relative;
  }
  .petals-image{
  width: 93px;
  height: 119px;
  }
  
  .content {
    max-width: 100%;
  }
  .contact-items {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 48px;
  }
  .mapBlock {
    width: 244px;
    height: 222px;
  }
  .contact-item{
      transform: rotate(-4deg);
      display: flex;
      flex-direction: column;
      gap:20px
  }
  .contact-logo{
    width: 156px;
    height: 40px;
  }
  .contact-number,
  .contact-link{
    font-size: 18px;
    margin: 0;
}
}
