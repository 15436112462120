@import '../../styles/Theme.module.scss';

.title{
color: $black;
font-family: $text9-font-family;
line-height: $text9-line-height;
font-size: 40px;
margin: 0;
text-align: center;
@media (max-width:768px) and (min-width:320px) {
    font-size: 28px;
}
}