@import "../../styles/Theme.module.scss";
.tasks-block {
  padding: 100px 0;
  margin-top: 100px;
  background-color: $gray;
  position: relative;
}
.petals-image{
  position: absolute;
  transform: rotate(180deg);
  top: -150px;
}
.letter{
  position: absolute;
  right: 0;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  max-width: 1140px;
  width: 100%;
  margin: auto;
}
.cards-block{
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 43px;
}
.card {
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  color: $black;
  font-family: $text3-font-family;
  line-height: $text3-line-height;
  font-size: 18px;
}
.glass{
  width: 32px;
  height: 35px;
}

@media (max-width:768px) and (min-width:320px) {
  .tasks-block {
   width: 100%;
   padding: 60px 15px;
  padding: 60px 0px;
  }
  .petals-image{
   width: 79px;
   height: 100px;
   top: -50px;
  }
  .content {
   gap: 30px;
  }
  .letter{
   display: none;
  }
  .cards-block{
    gap: 0px;
    margin: 0 15px;
}
.card{
  gap: 15px;
}
  .card {
    font-size: 14px;
    img{
      width: 16px;
      height: 17px;
    }
  }
}
