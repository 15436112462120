@import '../../styles/Theme.module.scss';

.menu-block{
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;
gap: 20px;
}
.menu-item{
    font-family: $text10-font-family;
    line-height: $text10-line-height;
    font-size: 14px;
    color: $black;
    text-decoration: none;
    display: flex;
    align-items: center;
    img{
        display: none;
    }
    &:hover,
    &:focus{
        color: $pink;
        img{
            display: block;
        }
    }
}
.item-onclick{
    font-family: $text10-font-family;
    line-height: $text10-line-height;
    font-size: 14px;
    color: $pink;
    text-decoration: none;
    display: flex;
    align-items: center;
    img{
        display: inline;
    }

}

@media  (max-width:1160px) and (min-width:970px) {
    .menu-item{
        font-size: 12px;
    }
}

@media  (max-width:970px) and (min-width:320px) {
    .menu-block{
        display: none;
    }
}