.container{
    padding-top: 94px;
    padding-bottom: 100px;
    position: relative;
}
.trearty-block{
display: flex;
flex-direction: column;
gap: 48px;
.textTreaty{
    max-width: 800px;
    width: 100%;
    margin: auto;
}
}
.mage-dots{
    position: absolute;
    left: 0;
}
.contentContainer{
    max-width: 800px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 60px;
    .title{
        font-weight: 700;
    }
}
.data{
    height: 26px;
    text-align: end;
    padding-bottom: 48px;
}
.image-heart{
    position: absolute;
    right: 0;
    bottom: 0;
}
@media (max-width:768px) and (min-width:320px) {
    .container{
        width: 100%;
        padding-top: 94px;
        padding-bottom: 67px;
        }
        .contentContainer{
            gap: 0;
        }
        .data{
            text-align: center;
            padding-bottom: 24px;
        }
        .trearty-block{
            padding: 0 15px;
            margin-top: 37px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            }
            .image-dots{
                display: none;
            }
            .image-heart{
               display: none;
            }
}