@import "../../styles/Theme.module.scss";
.partners-block {
  background-color: $gray;
  padding: 100px 0;
}
.partners-content {
  max-width: 1140px;
  width: 100%;
  margin: auto;
  display: flex;
}
.colum1 {
  flex: 1 30%;
  padding-top: 121px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.colum2 {
  flex: 1 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 100px;
}

@media (max-width: 768px) and (min-width: 320px) {
  .partners-block {
    padding: 60px 0;
  }
  .partners-content {
    flex-direction: column;
    gap: 40px;
  }
  .colum1 {
    flex: 1;
    padding-top: 0;
    position: relative;
  }
  .heart {
    @media (max-width: 768px) and (min-width: 478px) {
        position: absolute;
        max-width: 98px;
        height: 98px;
        left: -20px;
        top: 550%;
    }
    @media (max-width: 477px) and (min-width: 320px) {
        position: absolute;
        max-width: 98px;
        height: 98px;
        left: -20px;
        top: 920%;
    }

  }
  .colum2 {
    flex: 1;
    gap: 32px;
    img {
      max-width: 138px;
    }
  }
}
