.gallery-block {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    row-gap: 80px;
    justify-content: center;
    margin-top: 60px;
    img{
        width: 296px;
        height: 240px;
    }
}
.pink-sticker,
.black-sticker,
.heart,
.blue-star {
    position: absolute;
}
.gallery-sticker {
    position: relative;
    .pink-sticker {
        z-index: 3;
        width: 92px;
        height: 92px;
        left: -30px;
        top: -20px;
    }
    .black-sticker {
        z-index: 3;
        width: 92px;
        height: 92px;
        right: -15px;
        bottom: -20px;
    }
    .blue-star {
        z-index: 3;
        width: 120px;
        height: 157px;
        right: -60px;
        bottom: -100px;
    }
    .heart {
        z-index: 3;
        width: 200px;
        height: 200px;
        right: -30px;
        bottom: -100px;
    }
    @media (max-width:790px) and (min-width:320px) {
        .pink-sticker,
        .black-sticker {
            width: 80px;
            height: 80px;
        }
        .blue-star,
        .heart {
            width: 99px;
            height: 99px;
        }
    }

}
.image {
    right: 500px;
}
@media (max-width:768px) and (min-width:320px) {
    .diagonal-element {
        display: none;
    }
    .gallery-container {
        gap: 10px;
        row-gap: 20px;
        margin-top: 30px;
        position: relative;
        img {
            width: 136px;
            height: 136px;
        }
    }
}