@import '../../../styles/Theme.module.scss';

.treaty{
    max-width: 800px;
    width: 100%;
    margin: auto;
}
.arrow-button{
    background: none;
    border: none;
    padding: 0;
}
.treatly-swipe{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  .arrow{
    transform: rotate(-90deg);
    cursor: pointer;
  }
}

.notes-block{
    margin-top: 28px;
}

.link-container{
    display: flex;
    align-items: center;
    gap: 10px;
    .link{
        text-decoration: none;
        color: $black;
        font-size: 16px;
        font-family: $text4-font-family;
        line-height: $text4-line-height;
        &:hover,
        &:focus{
color: $pink;
        }
    }
}