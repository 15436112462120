@import '../../styles/Theme.module.scss';
.main-container{
    width: 100%;
}
.information-container{
    padding: 133px 0;
    max-width: 1140px;
    width: 100%;
    margin: auto;
}
.star{
    width: 124px;
    height: 89px;
    align-self: center;
    margin-top: -129px;
}
.dots{
    margin-top: 895px;
    position: absolute;
}
.information-block{
    display: flex;
    justify-content: space-between;
}
.blocks{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
    gap:77px
}
.text-container{
    display: flex;
    flex-direction: column;
    gap:40px;
}
.title{
    font-family: $text4-font-family;
    line-height: $text4-line-height;
    margin: 0;
    font-size: 18px;
   
}
.text,
.text-target{
line-height: $text6-line-height;
font-family: $text6-font-family;
font-size: 18px;
color: $black;
}
.title-target{
    font-size: 42px;
    font-family: $text5-font-family;
    line-height: $text5-line-height;
    margin: 0;
    text-transform: uppercase;
    @media (max-width:768px) and (min-width:320px) {
        font-size: 20px;
    }
}
.text-target{
    font-size: 18px;
    max-width: 520px;
    margin: 0;
}
.target-block{
    position: relative;
    padding: 136px 0;
    &::before{
        content: '';
        background: url(../../assets/target-bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        background-position: center;
        width: 100%;
        height: 100%;
        top: 0px;
        bottom: 0;
        right: 0;
        left: 0;
    z-index: 1;
      }
}
.target-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 79px;
  }

  @media (min-width:320px) and (max-width:768px){
    .dots{
        margin-top: 590px;
        left: -10px;
        width: 114px;
        height: 119px;
    }
    .title{
        margin: 0 15px;
        font-size: 14px;
    }
    .imageGirl{
        width: 186px;
        height: 204px;
        margin: 51px 0 32px 0;
    }
    .star{
        width: 71px;
        height: 50px;
    }
    .information-container{
        width: 100%;
    }
    .information-block{
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .blocks{
      width: 100%;
      gap: 0px;
      
    }
    .text-container{
        gap:24px;
    }
    .text{
        margin: 0 15px;
        font-size: 16px;
    }
    .hearts{
        order: 2;
        max-width: 120px;
        max-height: 120px;
    }
    .text-target{
        font-size: 16px;
        }
        .target-block{
            position: relative;
            padding: 81px 0px;
            &::before{
                content: '';
                background: url(../../assets/target-bg.png);
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                background-position: center;
                width: 100%;
                height: 100%;
                top: 0px;
                bottom: 0;
                right: 0;
                left: 0;
            z-index: 1;
              }
        }
  }