@import "../../styles/Theme.module.scss";

.burger-drop {
  display: none;
}

@media (max-width: 970px) and (min-width: 320px) {
  .burger-drop {
    display: inline;
  }
  .toggle-drop {
    border: none;
    background: none;
  }
  .dropdown-menuBurger {
    background-color: $white;
    z-index: 30;
    width: 100%;
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 39px;
    align-items: center;
    justify-content: center;
    padding-top: 36px;
    padding-bottom: 42px;
  }
  .list-container {
    margin-left: 15px;
    margin-right: 15px;
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-left: -85px;
    .burger-item {
      color: $black;
      text-decoration: none;
      font-family: $text3-font-family;
      line-height: $text3-line-height;
      font-size: 14px;
    }
  }
  .contact-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    transform: rotate(-4deg);
    margin-top: 39px;
  }
  .contact-items-text {
    display: flex;
    justify-content: space-between;
    .contact-items-adress {
      color: $black;
      font-family: $text9-font-family;
      line-height: $text9-line-height;
      font-size: 14px;
    }
    .contact-items-link {
      color: $blue;
      font-family: $subtitle-font-family;
      line-height: $subtitle-line-height;
      font-size: 14px;
      text-decoration: none;
    }
  }
  .contact-link-icon {
    align-self: center;
    display: flex;
    gap: 21px;
  }

  .hide-dropdown {
    display: none;
  }
}
