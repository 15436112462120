@import '../../../styles/Theme.module.scss';

.treatry-block{
    display: flex;
    gap: 20px;
    align-items: center;
}

.chapters{
    color: $black;
    font-family: $subtitle-font-family;
    line-height: $subtitle-line-height;
    font-size: 36px;
    margin: 0;
    @media (max-width:768px) and (min-width:320px) {
        font-size: 24px;
    }
}