@import '../../../styles/Theme.module.scss';

.treaty{
    max-width: 800px;
    width: 100%;
    margin: auto;
}
.arrow-button{
    background: none;
    border: none;
    padding: 0;
}
.treatly-swipe{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  .arrow{
    transform: rotate(-90deg);
    cursor: pointer;
  }
}

.notes-block{
    margin-top: 28px;
}

