@import "../../styles/Theme.module.scss";

.card {
  padding-top: 194px;
}
.card-main {
  width: 100%;
  background: url(../../assets/yellow-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  background-position: bottom;
  padding-bottom: 98px;
  @media (max-width:768px) and (min-width:320px) {
    padding-bottom: 60px;
  }
}
.card-description {
  color: $black;
  font-size: 18px;
  font-family: $text6-font-family;
  line-height: $text6-line-height;
  text-align: justify;
}
.card-container {
  max-width: 1140px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 100%;
  flex-grow: 0;
}

.news-photo {
  width: 100%;
  max-height: 450px;
  position: relative;
  .photo{
    width: 100%;
    object-fit: cover;
    max-height: 450px;
    @media (max-width:768px) and (min-width:320px) {
      height: 240px;
    }
  }
  .pinkStiker,
  .blackStiker{
    position: absolute;
    @media (max-width:768px) and (min-width:320px){
      width: 80px;
      height: 80px;
    }
  }
  .pinkStiker{
    top: -20px;
    left: -20px;
  }
  .blackStiker{
    bottom: -20px;
    right: -20px;
  }
}
.card-photo{
  width: 100%;
  position: relative;

  .content {
    display: block;
    img {
      float: left;
      margin-right: 30px;
    }
  }

  .photo{
    max-height: 500px;
    @media (max-width:768px) and (min-width: 320px) {
      height: 240px;
    }
    
  }
  .tackLeft{
    position: absolute;
    left: 10px;
    top: -20px;
    @media (max-width:768px) and (min-width:320px){
      width: 39px;
      height: 32px;
    }
  }
  .tackRight{
    position: absolute;
    right: -10px;
    top: -20px;
    @media (max-width:768px) and (min-width:320px){
      width: 39px;
      height: 32px;
    }
  }
}
.news-photo {
  margin: 80px 0;
}
.scroll-block {
  max-width: 1140px;
  width: 100%;
  margin: auto;
  margin-top: 40px;
}
.treaty-block {
  display: flex;
  justify-content: space-between;
}
.treaty-text {
  max-width: 50%;
  width: 100%;
}
.news-link {
  color: $black;
  font-size: 16px;
  font-family: $text4-font-family;
  line-height: $text4-line-height;
}
.chapters-title {
  position: relative;
}
.dots {
  position: absolute;
  top: 30%;
}
.line {
  position: absolute;
  bottom: 0;
}
.flow-container{
  display: flex;
  align-self: self-start;
  padding-bottom: 62px;
  color: $black;
  gap: 9px;
  font-family: $text11-font-family;
  line-height: $text11-line-height;
  font-size: 14px;
  align-items: center;
  img{
    width: 24px;
    height: 24px;
  }
  .link-flow{
    text-decoration: none;
    color: $black;
    &:hover{
      color: $pink;
    }
  }
}

@media (max-width: 768px) and (min-width: 320px) {
  .card{
    max-width: 100%;
    padding: 0 15px;
    padding-top: 94px;
  }
  .card-description {
   font-size: 16px;
  }
  .scroll-block {
    width: 100%;
  }
  .dots,
  .line  {
   display: none;
  }
  .treaty-block {
    display: flex;
    flex-direction: column;
  }
  .treaty-text {
    max-width: 100%;
  }
  .news-photo {
    margin: 30px 0;
  }
  .flow-container{
    gap: 4px;
    font-size: 10px;
    padding-bottom: 32px;
    img{
      width: 9px;
      height: 14px;
    }
  }

}
